<template>
  <div>
    <!-- Usuario seleccionado -->
    <div class="w-100 d-flex flex-column mb-2">
      <div v-if="selectedMember" class="mt-1 mb-2 w-100 d-flex flex-column align-items-center justify-content-center">
        <b-link :to="getMemberLocation(selectedMember)" class="mb-1">
          <user-avatar :user="selectedMember" size="60" />
        </b-link>
        <b-form-input
          v-model="searchString"
          class="mb-1"
          type="text"
          :placeholder="$t('members.search-placeholder')"
          v-on:input="handleChanger"
        />
        <div v-if="selectedMember && selectedMember.relationsCount === 0">
          <h6 class="title">
            {{ selectedMember.name }} {{ selectedMember.surname }} {{ $t('members.no-connections') }}
          </h6>
        </div>
        <div v-if="selectedMember && selectedMember.relationsCount === 1">
          <h6 class="title">
            {{ selectedMember.name }} {{ selectedMember.surname }} {{ $t('members.connection') }}
          </h6>
        </div>
        <div v-if="selectedMember && selectedMember.relationsCount > 1">
          <h6 class="title">
            {{ selectedMember.name }} {{ selectedMember.surname }}
            {{ $t('members.connections', { relationCount: selectedMember.relationsCount }) }}
          </h6>
        </div>
      </div>
      <div v-if="isRelationsLoading" class="mt-2 mb-2">
        <b-spinner class="d-block mx-auto mt-3" />
      </div>
    </div>

    <div v-if="!isRelationsLoading" class="w-100 mt-2">
      <small class="mb-2">{{ $t('members.members') }}:</small>
      <!-- Tabla de members del modal -->
      <b-table
        v-if="!isRelationsLoading"
        class="position-relative"
        thead-class="d-none"
        :items="myMemberRelationships"
        responsive
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        •
        <!-- Column: User -->
        <template #cell(fullname)="{ item }">
          <b-media class="d-flex align-items-center">
            <b-link :to="getMemberLocation(item)" class="text-dark d-flex">
              <div>
                <user-avatar :user="item" size="32" />
              </div>
              <div class="ml-1">
                <p class="font-weight-extrabold mb-0">
                  {{ `${item.name} ${item.surname}` }}
                </p>
                <small class="text-muted mb-0">
                  {{ item.headline || '--' }}
                </small>
              </div>
            </b-link>
          </b-media>
        </template>

        <!-- Column: Role -->
        <!--template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
            <p>{{ data.item.hasRelation }}</p>
          </div>
        </template-->

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill class="text-capitalize">
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="{ item }">
          <div v-if="item.key !== selectedMember.key" class="d-flex justify-content-end">
            <b-button
              v-if="!item.hasRelation"
              v-b-tooltip.hover
              pill
              :title="$t('members.no-friend-tooltip')"
              class="p-0 mr-1"
              variant="link"
              @click="handleCreateItem(selectedMember.key, item.key)"
            >
              <b-icon-person-plus variant="dark" size="16" class="align-middle text-body" />
            </b-button>
            <b-button
              v-if="item.hasRelation"
              v-b-tooltip.hover
              pill
              :title="$t('members.friend-tooltip')"
              class="p-0 mr-1"
              variant="link"
              @click="handleDeleteItem(selectedMember.key, item.key)"
            >
              <b-icon-person-check-fill variant="primary" size="16" class="align-middle text-body" />
            </b-button>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center p-5">
            <b-spinner
              size="2rem"
              type="grow"
              class="d-block m-auto m-1"
              variant="primary"
              label="Loading..."
            />
          </div>
        </template>
        <template #empty>
          <div class="horizontal-placeholder">
            <div>
              <img :src="membersPlaceholder" class="d-block">
            </div>
            <div>
              <p class="text-primary text-center">
                {{ $t('available.message', { itemName: $t('members.title') }) }}
              </p>
            </div>
          </div>
        </template>
      </b-table>

      <div
        v-if="myMemberRelationships && (myMemberRelationships.length+2) < total"
        class="w-100 mt-2 mb-2 d-flex justify-content-center"
      >
      <b-spinner
          v-if="isLoading"
          type="grow"
          small
          class="mx-auto d-block"
        />
        <b-button v-else variant="link" @click="handleLoadOfNewItems">
          {{ $t('members.show-more') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VBTooltip,
} from 'bootstrap-vue';
import MembersPlaceholder from '@/assets/images/placeholders/light/members.svg';
import UserAvatar from '@core/components/user/UserAvatar.vue';
import Ripple from 'vue-ripple-directive';
import { getImageResource } from '@/@core/utils/image-utils';

export default {
  name: 'MemberAddConnectionsModal',
  components: {
    VBTooltip,
    UserAvatar,
  },
  directives: { Ripple, 'b-tooltip': VBTooltip },
  props: {
    selectedMember: {
      type: Object,
      required: true,
    },
    isTable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isRelationsLoading: false,
      lastLoadedPage: 1,
      timer: null,
      searchString: null,
      isLoading: false,
    };
  },

  computed: {
    fields() {
      return [
        { key: 'fullname', label: this.$t('user.title') },
        { key: 'actions', label: '', sortable: false },
      ];
    },
    myMemberRelationships() {
      return [...new Set(this.$store.getters.myMemberRelationships.unpaginated)];
    },
    membersPlaceholder() {
      return MembersPlaceholder;
    },
    total() {
      return this.$store.getters.myMemberRelationships.meta.total;
    },
  },
  async created() {
    this.isRelationsLoading = true;
    this.lastLoadedPage = 1;
    await this.fetchData(this.selectedMember.key);
    this.isRelationsLoading = false;
  },
  methods: {
    getImageResource,

    // Fetch de todos los usuarios:
    async fetchData(memberKey, forceAPICall = true, searchString = null) {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'myMemberRelationships',
        page: this.lastLoadedPage,
        forceAPICall,
        perPage: 16,
        requestConfig: {
          orderByDate: -1,
          memberKey,
          searchString
        },
      });
      this.isLoading = false;
    },
    handleChanger() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        // your code
        this.lastLoadedPage = 1;
        this.fetchData(this.selectedMember.key, true, this.searchString );
      }, 800);
    },
    async handleLoadOfNewItems() {
      if (this.myMemberRelationships.length < this.total) {
        this.lastLoadedPage++;
        await this.fetchData(this.selectedMember.key, false);
      }
    },
    getMemberLocation(user) {
      if (!user.name) {
        return null;
      }
      return {
        name: 'member-details',
        params: { username: user.username, communityId: this.$route.params.communityId },
      };
    },

    // Create item
    async handleCreateItem(member1, member2) {
      try {
        await this.$store.dispatch('deleteMember', {
          itemType: 'myMemberRelationships',
          requestConfig: {
            key: member2,
          },
        });
        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'myMemberRelationships',
            requestConfig: {
              memberKeys: [member1, member2],
            },
          },
        });
        this.$emit('addConnection', member1, member2);
      } catch (error) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    // Delete item
    async handleDeleteItem(member1, member2) {
      try {
        await this.$store.dispatch('deleteItem', {
          item: {
            itemType: 'myMemberRelationships',
            requestConfig: {
              memberKeys: [member1, member2],
            },
          },
        });
        this.$emit('restConnection', member1, member2);
      } catch (error) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
  },
};
</script>

<style></style>
